import React, { useState } from "react";
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import IconX from '../../assets/X.png';
import { Link } from 'react-router-dom';
import Skilling01 from "../../assets/infinox/pamm/foto_01.png";
import Skilling02 from "../../assets/infinox/pamm/foto_02.png";
import Skilling03 from "../../assets/infinox/pamm/foto_03.png";
import Skilling04 from "../../assets/infinox/pamm/foto_04.png";
import Skilling05 from "../../assets/infinox/pamm/foto_05.png";
import Skilling06 from "../../assets/infinox/pamm/foto_06.png";
import Skilling07 from "../../assets/infinox/pamm/foto_07.png";
import Skilling08 from "../../assets/infinox/pamm/foto_08.png";
import Skilling09 from "../../assets/infinox/pamm/foto_09.png";
import Skilling10 from "../../assets/infinox/pamm/foto_10.png";
import Skilling11 from "../../assets/infinox/pamm/foto_11.png";
import Skilling12 from "../../assets/infinox/pamm/foto_12.png";
import Skilling13 from "../../assets/infinox/pamm/foto_13.png";
import Header from "../Header/Header2.js";


const StyledLink = styled(Link)`
  color: white;
  text-decoration: none;
  font-weight: bold;
  margin: 1rem;

  &:hover {
    text-decoration: underline;
    filter: brightness(0.8);
  } 
`;

const Fechar = styled.img``;

const Container = styled.div`
  background: #111111;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;


  @media (max-width: 1000px) {
    overflow-x: hidden !important;
  }
`;

const Title = styled.h1`
  text-align: center;
  width: 100%;
  color: white;
  font-size: 1.5rem;
  font-weight: 600;
  margin: 1rem;
`;

const TitleSub = styled.h1`
  text-align: center;
  width: 100%;
  color: #c3c3c3;
  font-size: 1.3rem;
  font-weight: 400;
  margin: 1rem;
`;

const TitleSub2 = styled.a`
  text-align: center;
  width: 100%;
  color: #c3c3c3;
  font-size: 1.3rem;
  font-weight: 400;
  margin-bottom: 1rem;
  color: #FFC222;
`;

const Card = styled.div`
  width: 1080px;
  background: #151515;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 14px;
  margin: 2rem;
  position: relative;

  @media (max-width: 1000px) {
   width: 100%;
   max-width: 400px;
  }
`;

const Botao = styled.button`
  width: 250px;
  height: 50px;
  background: #FFC222;
  cursor: pointer;
  font-weight: 600;
  color: white;
  font-size: 1.2rem;
  border-radius: 8px;
  border: none;
  transition: 500ms;
  margin: 8px;

  &:hover{
    filter: brightness(0.8);
  }
`;

const Image = styled.img`
  max-width: 100%;
  margin: 20px;
`;

const BolinhasContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
`;

const Bolinha = styled.div`
  width: 24px;
  height: 10px;
  border-radius: 14px;
  margin: 0 6px;
  cursor: pointer;
  background: ${(props) => (props.selected ? '#FFC222' : '#c3c3c3')};

  @media (max-width: 1000px) {
   width: 10px;
  }
`;

const TextPrincipal = styled.h1`
  color: #FFC222;
  margin-bottom: 1rem;
`;

const SubPrincipal = styled.p`
  color: white;
  font-size: 1.2rem;
`;

const Linkada = styled.a`
  color: #FFC222;
  margin: 0.5rem;
  transition: 500ms;
  font-size: 1.2rem;

  &:hover{
    filter: brightness(0.8);
  }
`;


const images = [Skilling01, Skilling02, Skilling03, Skilling04, Skilling05, Skilling06, Skilling07, Skilling08, Skilling09, Skilling10, Skilling11, Skilling12, Skilling13];

function Site() {
    const { t } = useTranslation();
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
  
    const nextImage = (index) => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const imageInfo = [
        {
            title: t('skillingT01'),
            cont: t('pamm.sub51')+t('pamm.sub52')
        },
        {
            title: t('skillingT02'),
            cont: t('pamm.sub53')+t('pamm.sub6')
        },
        {
          title: t('skillingT03'),
          cont: t('pamm.sub17')
        },
        {
          title: t('skillingT04'),
          cont: t('pamm.sub18')
        },
        {
          title: t('skillingT05'),
          cont: t('pamm.sub19')
        },
        {
          title: t('skillingT06'),
          cont: t('pamm.sub20')
        },
        {
          title: t('skillingT07'),
          cont: t('pamm.sub21')
        },
        {
          title: t('skillingT08'),
          cont: t('pamm.sub22')
        },
        {
          title: t('skillingT09'),
          cont: t('pamm.sub23')
        },
        {
          title: t('skillingT10'),
          cont: t('pamm.sub24')
        },
        {
          title: t('skillingT11'),
          cont: t('pamm.sub25')
        },
        {
          title: t('skillingT12'),
          cont: t('pamm.sub26')
        },
        {
          title: t('skillingT13'),
        },
    ];
    
    return (
        <Container>
          <Header/>
            <StyledLink to="/deposito-infinox">
                <Fechar src={IconX} alt="Fechar"/>   
            </StyledLink>
            <Card>
                <TextPrincipal>{t('pamm.sub.conservador')}</TextPrincipal>
                <TitleSub>{t('pamm.sub2')}</TitleSub>
                <StyledLink target="_blank" to="https://pamm6.infinox.com/app/join/185/uxqvuxyc">
                  <TitleSub2>https://pamm6.infinox.com/app/join/185/uxqvuxyc</TitleSub2>
                </StyledLink>
                <TitleSub>{t('pamm.sub3')}</TitleSub>
                <TitleSub>{t('pamm.sub4')}</TitleSub>
                
                <Image src={images[currentImageIndex]} alt={`Skilling ${currentImageIndex + 1}`} />
                <BolinhasContainer>
                    {images.map((_, index) => (
                        <Bolinha
                            key={index}
                            selected={index === currentImageIndex}
                            onClick={() => setCurrentImageIndex(index)} // Alterado aqui
                        />
                    ))}
                </BolinhasContainer>
                <Title>{imageInfo[currentImageIndex].title}</Title>
                <TitleSub>{imageInfo[currentImageIndex].cont}</TitleSub>
                <Botao onClick={nextImage}>{t('next.button')}</Botao>
            </Card>
        </Container>
    );
}

export default Site;
