import React, { useState } from "react";
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import IconX from '../../assets/X.png';
import { Link } from 'react-router-dom';
import Skilling01 from "../../assets/infinox/deposito-infinox/foto_01.png";
import Skilling02 from "../../assets/infinox/deposito-infinox/foto_02.png";
import Skilling03 from "../../assets/infinox/deposito-infinox/foto_03.png";
import Header from "../Header/Header2.js";


const StyledLink = styled(Link)`
  color: white;
  text-decoration: none;
  font-weight: bold;
  margin: 1rem;

  &:hover {
    text-decoration: underline;
    filter: brightness(0.8);
  } 
`;


const StyledLink2 = styled.a`
  color: white;
  text-decoration: none;
  font-weight: bold;
  margin: 1rem;

  &:hover {
    text-decoration: underline;
    filter: brightness(0.8);
  } 
`;

const StyledLink3 = styled.a`
  
`;

const Fechar = styled.img``;

const Container = styled.div`
  background: #111111;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;


  @media (max-width: 1000px) {
    overflow-x: hidden !important;
  }
`;

const Title = styled.h1`
  text-align: center;
  width: 100%;
  color: white;
  font-size: 1.5rem;
  font-weight: 600;
  margin: 1rem;
`;

const TitleSub = styled.h1`
  text-align: center;
  width: 100%;
  color: #c3c3c3;
  font-size: 1.3rem;
  font-weight: 400;
  margin-bottom: 1rem;
`;

const TitleSub2 = styled.a`
  text-align: center;
  width: 100%;
  color: #c3c3c3;
  font-size: 1.3rem;
  font-weight: 400;
  margin-bottom: 1rem;
  color: #FFC222;
`;

const Card = styled.div`
  width: 1080px;
  background: #151515;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 14px;
  margin: 2rem;
  position: relative;

  @media (max-width: 1000px) {
   width: 100%;
   max-width: 400px;
  }
`;

const Botao = styled.button`
  width: 250px;
  height: 50px;
  background: #FFC222;
  cursor: pointer;
  font-weight: 600;
  color: white;
  font-size: 1.2rem;
  border-radius: 8px;
  border: none;
  transition: 500ms;
  margin: 8px;

  &:hover{
    filter: brightness(0.8);
  }
`;


const ControllButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 1rem;
`;

const Botao2 = styled.button`
  width: 200px;
  height: 50px;
  background: none;
  cursor: pointer;
  font-weight: 600;
  color: white;
  font-size: 1.2rem;
  border-radius: 8px;
  border: 1px solid #FFC222;
  transition: 500ms;
  margin: 8px;

  &:hover{
    background: #FFC222;
  }
`;

const Image = styled.img`
  max-width: 100%;
  margin: 20px;
`;

const BolinhasContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
`;

const Bolinha = styled.div`
  width: 24px;
  height: 10px;
  border-radius: 14px;
  margin: 0 6px;
  cursor: pointer;
  background: ${(props) => (props.selected ? '#FFC222' : '#c3c3c3')};

  @media (max-width: 1000px) {
   width: 10px;
  }
`;

const TextPrincipal = styled.h1`
  color: #FFC222;
  margin-bottom: 1rem;
`;

const SubPrincipal = styled.p`
  color: white;
  font-size: 1.2rem;
`;

const Linkada = styled.a`
  color: #FFC222;
  margin: 0.5rem;
  transition: 500ms;
  font-size: 1.2rem;

  &:hover{
    filter: brightness(0.8);
  }
`;


const images = [Skilling01, Skilling02, Skilling03];

function Site() {
  const { t } = useTranslation();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const nextImage = () => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const imageInfo = [
      {
          title: t('skillingT01'),
          cont: t('infinox.sub1'),
      },
      {
          title: t('skillingT02'),
          cont: t('infinox.sub2')
      },
      {
        title: t('skillingT03'),
        cont: t('infinox.sub3'),
        cont2: t('infinox.sub4'),
        cont3: t('infinox.sub5'),
        cont4: t('infinox.button')
      },
  ];
  
  return (
      <Container>
          <Header/>
          <StyledLink to="/infinox">
              <Fechar src={IconX} alt="Fechar"/>   
          </StyledLink>
          <Card>
              <TextPrincipal>{t('infinox.title2')}</TextPrincipal>
              <Image src={images[currentImageIndex]} alt={`Skilling ${currentImageIndex + 1}`} />
              <BolinhasContainer>
                  {images.map((_, index) => (
                      <Bolinha
                          key={index}
                          selected={index === currentImageIndex}
                          onClick={() => setCurrentImageIndex(index)}
                      />
                  ))}
              </BolinhasContainer>
              <Title>{imageInfo[currentImageIndex].title}</Title>
              <TitleSub>{imageInfo[currentImageIndex].cont}</TitleSub>
              <TitleSub2>{imageInfo[currentImageIndex].cont4}</TitleSub2>

              {currentImageIndex === 2 && (
                <ControllButton>
                  <StyledLink3 href="/vincular-conta-pamm-moderado">
                    <Botao2>{t('deposito.infonix.text.1')}</Botao2>
                  </StyledLink3>

                  <StyledLink3 href="/vincular-conta-pamm-conservador">
                    <Botao2>{t('deposito.infonix.text.2')}</Botao2>
                  </StyledLink3>

                  <StyledLink3 href="/vincular-conta-pamm-agressivo">
                    <Botao2>{t('deposito.infonix.text.3')}</Botao2>
                  </StyledLink3>
                </ControllButton>      
              )}

              <Botao onClick={nextImage}>{t('next.button')}</Botao>
          </Card>
      </Container>
  );
}

export default Site;

