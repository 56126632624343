import React from "react";
import Header from "../Header/Headeren.js";
import Section from "../Section/Section.js";
import Card from "../Card/Card.js";
import Card2 from "../Card2/Card2.js";
import Card3 from "../Card3/Card3.js";
import Site from "../SiteCard/Site.js";
import Contato from "../Contato/Contato.js";
import Faq from "../Faq/Faq.js";
import Footer from '../Footer/Footer.js';
import Local from '../Local/Local.js';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import VideoPT from './videopt.js';
import VideoES from './videoes.js';
import VideoEN from './videoen.js';
import VideoFR from './videofr.js';
import Carrosel from '../Contato/Carrossel.js';
import Form from '../Contato/Form.js';

const VideoContainer = styled.div`
  width: 100%;
`;

const Luz = styled.div`
  position: absolute;
  z-index: -1;
  width: 400px;
  height: 240px;
  background: rgba(255, 194, 34, 0.5);
  border-radius: 100px;
  filter: blur(200px); /* Valor do desfoque, ajuste conforme necessário */
  top: -100px; /* Ajuste conforme necessário para a posição desejada */
  left: 1100px; /* Ajuste conforme necessário para a posição desejada */

  animation: pulse3 2s infinite alternate;
  
  @keyframes pulse3 {
    from {
      opacity: 0.2;
    }
    to {
      opacity: 1;
    }
  }

  @media (max-width: 1000px) {
        display: none;
    }

`;

const HomeContainer = styled.div`
  z-index: 1;
  @media (max-width: 1000px) {
    overflow-x: hidden !important;
  }
`;

function Home() {
  const { i18n } = useTranslation();


  return (
    <HomeContainer>
      <Luz></Luz>
      <Header/>
      <Section/>
      <Carrosel/>
      <Card2/>
      <Footer/>
    </HomeContainer>
  );
}

export default Home;
