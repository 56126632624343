import React, { useEffect } from "react";
import styled from 'styled-components';
import CelularPhoto from '../../assets/celular_results.png';
import CelularVideo from '../../assets/celular_results.mp4';
import { useTranslation } from 'react-i18next';
import { Element } from 'react-scroll';
import { Link } from 'react-router-dom';


const Video2 = styled.video`
  width: 100%;
  height: 100%;
  pointer-events: none; /* Impede a interação com o vídeo */
  display: block;

  @media (max-width: 1000px) {
        display: none;
    }
`;  

const StyledLink = styled(Link)`
  color: white;
  text-decoration: none;
  font-weight: bold;
  margin-top: 1rem;

  `;

const FadeIn = styled.div`
  opacity: 0;
  transform: translateY(150px); /* Aumentei a quantidade de translação */
  transition: opacity 0.2s ease, transform 1s ease; /* Reduzi a duração da transição */
  
  &.visible {
    opacity: 1;
    transform: translateY(0);
  }
`;


const Container = styled.div`
  background: #111111;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;

  @media (max-width: 1000px) {
    overflow-x: hidden !important;
  }
`;


const Div = styled.div`
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;


const Line = styled.div`
    margin-top: 2rem;
    background: #FFC222;
    width: 2px;
    height: 75px;
`;

const Celular = styled.img`
  max-width: 600px;
  margin: 1rem;
  display: none;

  @media (max-width: 1000px) {
        display: block;
    }
`;


const Title = styled.h1`
  font-weight: bold;
  color: white;
  font-size: 42px;

    span{
    color: #FFC222;
  }

  @media (max-width: 1000px) {
    width: 400px;
    text-align: center;
    font-size: 24px;
    }
`;

const Botao = styled.button`
  margin-top: 8px;
  width: 244px;
  height: 50px;
  background: none;
  cursor: pointer;
  font-weight: 600;
  color: white;
  font-size: 1.2rem;
  border-radius: 10px;
  border: 1px solid white;
  transition: 500ms;

  &:hover{
    background: #FFC222;
    border: none;
  }

  @media (max-width: 1000px) {
        margin-bottom: 2rem;
    }


`;


function Card() {
    const { t } = useTranslation();

    useEffect(() => {
        const handleScroll = () => {
          const element = document.querySelector('.fade-in3');
          const elementPosition = element.getBoundingClientRect().top;
          const screenHeight = window.innerHeight;
    
          if (elementPosition < screenHeight) {
            element.classList.add('visible');
          } else {
            element.classList.remove('visible'); // Remove a classe se o elemento não estiver mais visível
          }
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);

  return (

    <Container>
        <Element name="section">
        <FadeIn className="fade-in3">
        <Div>  
          <Line></Line>
          <Celular src={CelularPhoto} alt="Celular"/>
          <Video2 src={CelularVideo} autoPlay loop muted alt="Rise Robô" title="Rise Robô"/>
          <Title>{t('results.title')}<span>:</span></Title>
          <StyledLink target="_blank" to="https://www.myfxbook.com/members/edusabbag/rise-robo/10417090">
                    <Botao>{t('card.button')}</Botao>
          </StyledLink>
        </Div>
        </FadeIn>
    </Element>
    </Container>

  );
}

export default Card;
