import React, { useState } from "react";
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import BannerAll from "../../assets/Trade/BANNERS-RESULTS.png";



const StyledLink = styled(Link)`
  color: white;
  text-decoration: none;
  font-weight: bold;
  margin-top: 1rem;

  &:hover {
    text-decoration: underline;
    filter: brightness(0.8);
  } 
`;

const StyledLink2 = styled(Link)`
  color: white;
  text-decoration: none;
  font-weight: bold;
  margin:1rem;
`;


const TitleSub2 = styled.button`
  width: 200px;
  height: 50px;
  background: none;
  cursor: pointer;
  font-weight: 600;
  color: white;
  font-size: 1.2rem;
  border-radius: 8px;
  border: 1px solid #FFC222;
  transition: 500ms;
  margin: 8px;

  &:hover{
    background: #FFC222;
  }
`;

const Fechar = styled.img``;

const Container = styled.div`
  background: #151515;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;


  @media (max-width: 1000px) {
    overflow-x: hidden !important;
  }
`;

const Title = styled.h1`
  text-align: center;
  width: 100%;
  color: transparent;
  font-size: 3rem;
  font-weight: 600;
  margin: 0.1rem;
  background: linear-gradient(to right, #aa7d3c, #f2cf8d);
  -webkit-background-clip: text;

  @media (max-width: 1000px) {
    font-size: 2rem;
  }
`;

const TitleSub = styled.h1`
  text-align: center;
  width: 100%;
  color: #c3c3c3;
  font-size: 1.3rem;
  font-weight: 400;
  margin-bottom: 1rem;
`;

const Card = styled.div`
  width: 1700px;
  background: #151515;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 14px;
  margin: 2rem;
  position: relative;

  @media (max-width: 1000px) {
   width: 100%;
   max-width: 400px;
  }
`;

const Botao = styled.button`
  width: 250px;
  height: 50px;
  background: linear-gradient(to right, #aa7d3c, #f2cf8d);
  cursor: pointer;
  font-weight: 600;
  color: black;
  font-size: 1.2rem;
  border-radius: 8px;
  border: none;
  transition: 500ms;
  margin: 8px;

  &:hover{
    filter: brightness(0.8);
  }
`;

const Image = styled.img`
  width: 1700px;
  margin: 10px;


  @media (max-width: 1000px) {
   width: 500px;
  }


  @media (max-width: 300px) {
   width: 100%;
  }
`;

const BolinhasContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
`;

const Bolinha = styled.div`
  width: 24px;
  height: 10px;
  border-radius: 14px;
  margin: 0 6px;
  cursor: pointer;
  background: ${(props) => (props.selected ? '#f2cf8d' : '#c3c3c3')};

  @media (max-width: 1000px) {
   width: 10px;
  }
`;

const TextPrincipal = styled.h1`
  color: transparent;
  background: linear-gradient(to right, #aa7d3c, #f2cf8d);
  -webkit-background-clip: text;
  margin-bottom: 1rem;
`;

const SubPrincipal = styled.p`
  color: white;
  font-size: 1.2rem;
`;

const Linkada = styled.a`
  color: transparent;
  margin: 0.5rem;
  transition: 500ms;
  font-size: 1.2rem;
  background: linear-gradient(to right, #aa7d3c, #f2cf8d);
  -webkit-background-clip: text;

  &:hover{
    filter: brightness(0.8);
  }
`;



function Site() {
    const { t } = useTranslation();

    return (
        <Container>
            <Card>
              <Title>{t('header.title5')}</Title>
              <Image src={BannerAll} alt="Skilling" />
            
            </Card>
        </Container>
    );
}

export default Site;
